@import "~antd/dist/antd.css";
.App {
    height: 100vh;
}
.logo {
    height: 25px;
    width: 30px;
    background-color: aqua;
}
/* Home page start */
.app-header {
    position: fixed;
    top: 0;
    height: 50px !important;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
    z-index: 1000;
    padding: 0;
    line-height: 1;
}

.side-nav {
    height: calc(100vh - 50px);
    position: fixed;
    top: 50px;
    box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px -1px;
    background-color: rgb(255, 255, 255);
    overflow: auto;
    text-align: justify;
    min-width: 200px !important;
    transition: all 0.2s ease-in-out;
}

.body-element.active .side-nav {
    min-width: 70px !important;
    width: 70px !important;
}

.body-element .app-layout {
    padding-left: 200px;
    transition: all 0.2s ease-in-out;
}
.body-element.active .app-layout {
    padding-left: 70px;
}

.body-element .app-content {
    padding: 0;
    margin-top: 50px;
    min-height: calc(100vh - 50px);
    position: relative;
}

.body-element .app-content .ant-layout-content {
    height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}
.site-layout-background {
    background: #fff;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
}
.gutter-box {
    padding: 10px 0;
    border-radius: 8px;
    background: #00a0e9;
}
.example {
    margin: 20px 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000;
}
.home-latest-txn > ant-card-body {
    padding: 4px;
}

.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ul {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}
.ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}
.steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 80px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

.steps-action {
    margin-top: 24px;
}
